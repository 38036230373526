import { ref, watch, computed } from '@vue/composition-api';
import store from '@/store';
import router from '@/router';
import i18n from '@/libs/i18n';

// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default function useRequestsList() {
  // Use toast
  const toast = useToast();

  const refRequestListTable = ref(null);

  // Table Handlers
  const tableColumns = computed(() => {
    if (store.getters['profile/isBuyerOnly'] && !store.getters['profile/isAdmin']) {
      return [
        { key: 'id', label: '#', sortable: true },
        { key: 'title', label: i18n.t('title'), sortable: true },
        { key: 'date', label: i18n.t('dateTerm'), sortable: true },
        { key: 'products', label: i18n.t('products'), sortable: true },
        { key: 'status', label: i18n.t('status'), sortable: true },
        { key: 'actions', label: i18n.t('actions') },
      ];
    }
    return [
      { key: 'id', label: '#', sortable: true },
      { key: 'title', label: i18n.t('title'), sortable: true },
      { key: 'date', label: i18n.t('dateTerm'), sortable: true },
      { key: 'buyer', label: i18n.t('buyer'), sortable: true },
      { key: 'products', label: i18n.t('products'), sortable: true },
      { key: 'status', label: i18n.t('status'), sortable: true },
      { key: 'actions', label: i18n.t('actions') },
    ];
  });
  const perPage = ref(10);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const sortBy = ref('id');
  const isSortDirDesc = ref(true);
  const buyerFilter = ref([]);
  const buyerCountriesFilter = ref([]);
  const filterValue = ref(null);
  const statusFilter = ref(null);
  const verifiedFilter = ref(null);

  const items = computed(() => store.state.requests.requests);
  const totalRequests = computed(() => store.state.requests.meta.total);

  const dataMeta = computed(() => {
    const localItemsCount = items.value?.length || 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalRequests.value,
    };
  });

  watch([currentPage, perPage, buyerFilter, buyerCountriesFilter, statusFilter, verifiedFilter], async () => {
    await refetchData();
  });

  const resolveDate = date => {
    if (date) {
      const getDate = new Date(date);
      const day = getDate.getDate() < 9 ? `0${getDate.getDate()}` : getDate.getDate();
      const month = (getDate.getMonth() + 1) <= 9 ? `0${(getDate.getMonth() + 1)}` : getDate.getMonth() + 1;
      return `${day}.${month}.${getDate.getFullYear()}`;
    }
    return i18n.t('unlimited');
  };

  const fetchRequests = async () => {
    const isParamExist = ['all', 'open', 'own', 'current', 'offers']
      .includes(router.currentRoute.params.param);
    let routeParam = router.currentRoute.params.param;
    if (routeParam === 'all' && !store.getters['profile/isAdmin']) routeParam = 'open';
    if (isParamExist) {
      try {
        await store.dispatch('requests/fetch', {
          fetchParam: routeParam,
          data: {
            per_page: perPage.value,
            page: currentPage.value,
            owned_by_legal_ids: buyerFilter.value.map(({ id }) => id),
            owned_by_country_ids: buyerCountriesFilter.value.map(({ id }) => id),
            is_published: statusFilter.value?.value || undefined,
            is_verified: verifiedFilter.value?.value || undefined,
          },
        });
      } catch (err) {
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t('fetchingRequestsError'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      }
    }
  };

  const refetchData = async () => {
    if (refRequestListTable.value) {
      refRequestListTable.value.refresh();
    }
    await fetchRequests();
  };

  // *===============================================---*
  // *--------------------- UI -------------------------*
  // *===============================================---*

  const resolveRequestStatusVariantAndIcon = status => {
    if (status) {
      return { variant: 'danger', icon: 'InfoIcon', status: i18n.t('notVerified') };
    }
    return { variant: 'success', icon: 'CheckCircleIcon', status: i18n.t('published') };
  };

  const resolveRequestStatus = status => {
    if (!status) {
      return { variant: 'deal__not-published', status: i18n.t('notPublished') };
    }
    if (status === 1) {
      return { variant: 'deal__equipment', status: i18n.t('pendingVerification') };
    }
    return { variant: 'deal__published', status: i18n.t('published') };
  };

  const toString = value => {
    if (value === null || typeof value === 'undefined') {
      return '';
    } if (value instanceof Object) {
      return Object.keys(value)
        .sort()
        .map(key => toString(value[key]))
        .join(' ');
    }
    return String(value);
  };

  const getRequestInfo = data => {
    const isProductsExist = !!data.products.length;
    const [product] = data.products;
    const name = isProductsExist ? product.name : '';
    const count = isProductsExist ? product.count : 0;
    const index = isProductsExist ? product.index : '';
    const productImage = product?.images[0]?.url || require('@/assets/images/placeholders/product-placeholder.svg');

    const supplier = data.deal?.offer?.owned_by;

    return {
      isProductsExist,
      productInfo: `${name}... - ${count}pcs`,
      productIndex: index,
      productImage,
      buyer: {
        companyName: data.user?.company.company_name,
        avatar_url: data.user?.profile.avatar_url,
        country: data.user?.profile.country?.name,
      },
      supplier: {
        exists: !!data.deal?.offer,
        companyName: supplier?.company?.company_name,
        avatar_url: supplier?.profile?.avatar_url,
        country: supplier?.profile?.country?.name,
      },
      price: '0.00',
    };
  };

  const sortCompare = (aRow, bRow, key, sortDesc, formatter, compareOptions, compareLocale) => {
    if (key === 'date') {
      return aRow.created_at < bRow.created_at ? -1 : aRow.created_at > bRow.created_at ? 1 : 0;
    }
    const a = aRow[key];
    const b = bRow[key];
    if (
      (typeof a === 'number' && typeof b === 'number')
      || (a instanceof Date && b instanceof Date)
    ) {
      return a < b ? -1 : a > b ? 1 : 0;
    }
    return toString(a).localeCompare(toString(b), compareLocale, compareOptions);
  };

  return {
    fetchRequests,
    tableColumns,
    perPage,
    items,
    currentPage,
    totalRequests,
    dataMeta,
    perPageOptions,
    sortBy,
    isSortDirDesc,
    refRequestListTable,

    getRequestInfo,
    buyerFilter,
    buyerCountriesFilter,
    statusFilter,
    filterValue,
    verifiedFilter,
    resolveRequestStatusVariantAndIcon,
    resolveRequestStatus,
    sortCompare,

    resolveDate,
    refetchData,
  };
}
